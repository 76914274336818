@use 'partials' as *;

.linkButton {
  max-width: 100%;
  font-size: size(15);
  font-weight: bold;
  color: var(--color-white);
  white-space: nowrap;
  background-color: var(--color-dark);
  border: size(2) solid var(--color-dark);
  border-radius: size(4);

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.default {
  width: 100%;
  height: size(50);
}

.xSmall {
  width: auto;
  height: size(25);
  padding: 0 size(16);
  font-size: size(10);
}

.small {
  width: size(70);
  height: size(40);
}
  
.large {
  width: 100%;
  height: size(50);
  font-size: size(15);
}
  
.xLarge {
  width: 100%;
  height: size(50);
  font-size: size(15);
}

.fullWidth {
  width: 100%;
  height: size(50);
}

.link {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

@include media-min(pc) {
  .default {
    width: size(400);
    height: size(60);
    font-size: size(18);
  }

  .xSmall {
    width: auto;
    height: size(40);
    padding: 0 size(24);
    font-size: size(14);
  }

  .small {
    width: size(100);
    height: size(50);
    font-size: size(18);
  }

  .large {
    width: size(400);
    height: size(60);
    font-size: size(18);
  }

  .xLarge {
    width: 100%;
    height: size(80);
    font-size: size(24);
  }

  .fullWidth {
    width: 100%;
    height: size(60);
    font-size: size(18);
  }
}
