.ErrorPage_componentStyle__S3zsp{text-align:center}.ErrorPage_errorCode__KfsxV{font-size:3.125rem}.ErrorPage_errorText__l8g6D{font-size:1.125rem}@media screen and (min-width: 800px){.ErrorPage_errorCode__KfsxV{font-size:4.5rem}.ErrorPage_errorText__l8g6D{font-size:1.5rem}}
/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_cb24e3';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/490324aba436aae6-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_cb24e3';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/2775879c0878691b-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_cb24e3';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/48514a352cc399ab-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_cb24e3';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/73786b255bc971b2-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_cb24e3';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/ff2196f1ce0ba75c-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Montserrat_Fallback_cb24e3';src: local("Arial");ascent-override: 84.95%;descent-override: 22.03%;line-gap-override: 0.00%;size-adjust: 113.95%
}.__className_cb24e3 {font-family: '__Montserrat_cb24e3', '__Montserrat_Fallback_cb24e3', sans-serif;font-weight: 700;font-style: normal
}

.LinkButton_linkButton__BRTz6{max-width:100%;font-size:0.9375rem;font-weight:bold;color:var(--color-white);white-space:nowrap;background-color:var(--color-dark);border:0.125rem solid var(--color-dark);border-radius:0.25rem}.LinkButton_linkButton__BRTz6:disabled{cursor:not-allowed;opacity:.5}.LinkButton_default__5cZS_{width:100%;height:3.125rem}.LinkButton_xSmall__57oV_{width:auto;height:1.5625rem;padding:0 1rem;font-size:0.625rem}.LinkButton_small__RF8C0{width:4.375rem;height:2.5rem}.LinkButton_large__W7LAW{width:100%;height:3.125rem;font-size:0.9375rem}.LinkButton_xLarge__khawC{width:100%;height:3.125rem;font-size:0.9375rem}.LinkButton_fullWidth__Oa1eb{width:100%;height:3.125rem}.LinkButton_link__1Q2xr{display:flex;flex-flow:row nowrap;align-items:center;justify-content:center;width:100%;height:100%}@media screen and (min-width: 800px){.LinkButton_default__5cZS_{width:25rem;height:3.75rem;font-size:1.125rem}.LinkButton_xSmall__57oV_{width:auto;height:2.5rem;padding:0 1.5rem;font-size:0.875rem}.LinkButton_small__RF8C0{width:6.25rem;height:3.125rem;font-size:1.125rem}.LinkButton_large__W7LAW{width:25rem;height:3.75rem;font-size:1.125rem}.LinkButton_xLarge__khawC{width:100%;height:5rem;font-size:1.5rem}.LinkButton_fullWidth__Oa1eb{width:100%;height:3.75rem;font-size:1.125rem}}
